
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import {
  Typography,
  Icon,
} from '@material-ui/core'

const styles = theme => ({
  note: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    borderWidth: "2px",
    borderStyle: "solid",
    borderRadius: "5px",
  },
  info: {
    color: "#17a2b8",
  },
  infoborder: {
    borderColor: "#17a2b8",
  },
  infoIcon: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: "#17a2b8",
    float: 'left',
  },
  success: {
    color: "#5cb85c",
  },
  successborder: {
    borderColor: "#28a745",
  },
  successIcon: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: "#28a745",
    float: 'left',
  },
  warning: {
    color: theme.palette.secondary.main,
  },
  warningborder: {
    borderColor: theme.palette.secondary.dark,
  },
  warningIcon: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.secondary.dark,
    float: 'left',
  },
  danger: {
    color: theme.palette.error.main,
  },
  dangerborder: {
    borderColor: theme.palette.error.dark,
  },
  dangerIcon: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.error.dark,
    float: 'left',
  }
})

@withStyles(styles)
export class InfoNote extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div className={clsx({ [classes.note]: true, [classes.infoborder]: true, [classes.info]: !this.props.onlyBorder })}>
        <Icon className={classes.infoIcon}>speaker_notes</Icon>
        <Typography>{this.props.children}</Typography>
      </div>
    )
  }
}

InfoNote.propTypes = {
  children: PropTypes.node.isRequired,
}

@withStyles(styles)
export class SuccessNote extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div className={clsx({ [classes.note]: true, [classes.successborder]: true, [classes.success]: !this.props.onlyBorder })}>
        <Icon className={classes.successIcon}>check_circle</Icon>
        <Typography>{this.props.children}</Typography>
      </div>
    )
  }
}

SuccessNote.propTypes = {
  children: PropTypes.node.isRequired,
}

@withStyles(styles)
export class WarningNote extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div className={clsx({ [classes.note]: true, [classes.warningborder]: true, [classes.warning]: !this.props.onlyBorder })}>
        <Icon className={classes.warningIcon}>warning</Icon>
        <Typography>{this.props.children}</Typography>
      </div>
    )
  }
}

WarningNote.propTypes = {
  children: PropTypes.node.isRequired,
}

@withStyles(styles)
export class DangerNote extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div className={clsx({ [classes.note]: true, [classes.dangerborder]: true, [classes.danger]: !this.props.onlyBorder })}>
        <Icon className={classes.dangerIcon}>error</Icon>
        <Typography>{this.props.children}</Typography>
      </div>
    )
  }
}

DangerNote.propTypes = {
  children: PropTypes.node.isRequired,
}
