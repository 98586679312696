import React from 'react'
import Page from '../../components/page'
import withRoot from '../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import { Link, navigate } from 'gatsby'
import {
  Typography,
  Button,
} from '@material-ui/core'
import { SuccessNote } from '../../components/highlight'

const styles = theme => ({
  parag: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.light,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
})

@withRoot
@withStyles(styles)
export default class AlreadyPaid extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <Page toggleTheme={this.props.toggleTheme} title="Already Paid?">
        <Typography variant="h4">I already purchased? Do I need to pay again?</Typography>
        <Typography className={classes.parag}>
          Unfortunately Fitbit does not always allow us to recognize you again after (re)installing a clockface,
          so our system might think you are a new customer when that happens, and might ask you to pay again.
        </Typography>
        <Typography className={classes.parag}>
          Luckily you just need to unlock your clockface again like you did the first time.
        </Typography>
        <Typography>
          You don&apos;t know how to unlock your clockface. Follow the simple steps go <Link className={classes.link} to="/user/unlock#complete">here</Link>.
        </Typography>
        <SuccessNote onlyBorder>
          You don&apos;t want to unlock every time you swap between your clockfaces, use our <b>free generic clockface</b>.
          It is a unique clockface that permits to manage and install all your FitFace purchases (and the free designs from FitFace too)
          <Typography>
            No more unlock needed. You just login once and your done.
          </Typography>
          <Typography>
            Follow the detailed procedure <Link className={classes.link} to="/user/getstarted">here</Link>
          </Typography>
        </SuccessNote>
        <Button onClick={() => navigate('/')}>Back to FAQ</Button>
      </Page>
    )
  }
}
